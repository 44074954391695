import React from 'react';
import './styles.css';

export const BannerComponent = ({ imageUrl, linkUrl }) => {
  const handleClick = () => {
    window.open(linkUrl, '_blank');
  };

  return (
    <div className="banner-container" onClick={handleClick}>
      <img src={imageUrl} alt="Banner" className="banner-image" />
    </div>
  );
};
