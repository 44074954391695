import React, { useEffect, useState } from "react";
import { Footer, Inicio, PreparacionIntegralPage, ClasePersonalizada, MasterClass, Header, PopupComponent } from "./components"
import 'bootstrap/dist/css/bootstrap.min.css'
import { Routes, BrowserRouter as Router, Route } from 'react-router-dom'

function App() {

  const [showPopup, setShowPopup] = useState(false);
  const [hasPopupShown, setHasPopupShown] = useState(false);

  useEffect(() => {
    if (!hasPopupShown) {
      const timer = setTimeout(() => {
        setShowPopup(true);
        setHasPopupShown(true);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [hasPopupShown]);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", backgroundColor: '#ffffff', alignItems: 'center' }}>
      {showPopup && <PopupComponent onClose={handleClosePopup} />}
      <Router>
        <Header />
        <div>
          <Routes>
            <Route path="/" exact element={<Inicio />} />
            <Route path="/preparacionIntegral" exact element={<PreparacionIntegralPage />} />
            <Route path="/clasePersonalizada" exact element={<ClasePersonalizada />} />
            <Route path="/MasterClass" exact element={<MasterClass />} />
          </Routes>
        </div>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
