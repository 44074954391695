import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import './carousel.css'
import antonella from '../../assets/images/antonella.jpg'
import melanie from '../../assets/images/melanie.jpg'
import renata from '../../assets/images/renata.jpg'
import gabriela from '../../assets/images/gabriela.jpg'
import martina from '../../assets/images/martina.jpg'
import misaela from '../../assets/images/misaela.jpg'
import evangelina from '../../assets/images/evangelina.jpg'
import Title from './styles'


export const CarouselHeader = () => {
    return (
        <div>
            <Carousel fade>
                <Carousel.Item>
                    <img
                        className="d-block w-100 imagen-carousel"
                        src={gabriela}
                        alt="Gabriela"
                    />
                    <Carousel.Caption>
                        <Title>Gabriela Vegas</Title>
                        <p>Miss Cultura Continental Venezuela 2023</p>
                        <p>Virreina Miss Cultura Continental Internacional 2023</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 imagen-carousel"
                        src={antonella}
                        alt="Antonella"
                    />
                    <Carousel.Caption>
                        <Title>Antonella Silva</Title>
                        <p>Petite Towering Argentina 2023</p>
                        <p>Petite Towering Universe 1RU 2023</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 imagen-carousel"
                        src={melanie}
                        alt="Melanie"
                    />
                    <Carousel.Caption>
                        <Title>Melany Gonzalez</Title>
                        <p>Miss Universo Santa Cruz 2023</p>
                        <p>4° Finalista Miss Universo Argentina 2023</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 imagen-carousel"
                        src={renata}
                        alt="Renata"
                    />
                    <Carousel.Caption>
                        <Title>Renata Pascotto</Title>
                        <p>Miss Universo Formosa 2023</p>
                        <p>Top 10 Miss Universo Argentina 2023</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 imagen-carousel"
                        src={martina}
                        alt="Martina"
                    />
                    <Carousel.Caption>
                        <Title>Martina Irigaray</Title>
                        <p>Teen Cultura Continental Argentina 2024</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 imagen-carousel"
                        src={misaela}
                        alt="Misaela"
                    />
                    <Carousel.Caption>
                        <Title>Misaela Perez</Title>
                        <p>Miss Beauty Argentina 2024</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 imagen-carousel"
                        src={evangelina}
                        alt="Evangelina"
                    />
                    <Carousel.Caption>
                        <Title>Evangelina Monchiero</Title>
                        <p>Miss Universo Neuquen 2024</p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>

        </div>
    )
}
