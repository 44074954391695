import React from 'react'
import { Container, Title, Parrafo, RostroYTaconContainer, ContainerSecundario } from './styles'
import correo from '../../assets/Icons/correo.png'
import instagram from '../../assets/Icons/instagram.png'
import telefono from '../../assets/Icons/telefono.png'
import ubicacion from '../../assets/Icons/ubicacion.png'
import { PhoneNumberComponent } from '../PhoneNumber/index'
import {EmailComponent} from '../MailTo/index'

export const Footer = () => {

  const iconInstagramStyles = {
    width: '60px',
    height: '60px',
    marginTop: '15px'
  };

  const iconsContainerStyles = {
    width: '30px',
    height: '30px',
    marginTop: '10px'
  };

  const imagenContainer = {
    marginTop: '10px',
    display: "flex"
  }

  const urlPerfilInstagram = 'https://www.instagram.com/rostroytacon/';

  const redirigirAInstagram = () => {
    window.open(urlPerfilInstagram, '_blank');
  };

  return (
    <Container>
      <ContainerSecundario>
        <div>
          <Title>SÍGUENOS</Title>
          <img src={instagram} alt="Logo" style={iconInstagramStyles} onClick={redirigirAInstagram} />
          <div style={imagenContainer}>
            <img src={ubicacion} alt="Logo" style={iconsContainerStyles} />
            <Parrafo>Buenos Aires, Argentina</Parrafo>
          </div>
          <div style={imagenContainer}>
            <img src={telefono} alt="Logo" style={iconsContainerStyles} />
            <Parrafo>
              <PhoneNumberComponent />
            </Parrafo>
          </div>
          <div style={imagenContainer}>
            <img src={correo} alt="Logo" style={iconsContainerStyles} />
            <Parrafo>
              <EmailComponent/>
            </Parrafo>
          </div>
        </div>
        <RostroYTaconContainer>
          <Title>ROSTRO Y TACON</Title>
          <Parrafo>Además de su excepcional equipo docente, esta academia cuenta con una gran variedad de recursos y oportunidades exclusivas para sus alumnos. Una de las ventajas más destacadas es su amplia red de contactos en la industria de la belleza. Gracias a esta red, los estudiantes tienen la oportunidad de participar en desfiles, sesiones fotográficas y eventos de renombre, lo que les permite ganar experiencia y darse a conocer en el mundo de la belleza.</Parrafo>
        </RostroYTaconContainer>
      </ContainerSecundario>
      <p style={{ marginTop: '30px', color: 'white' }}>© rostroytacón 2020. Todos los derechos reservados.</p>
      <p style={{ color: 'white' }}>rostro y tacón y otras son marcas registradas cuya titularidad es de Soluciones Feathers. La información facilitada en este documento es orientativa, no tiene carácter vinculante y carece de valor contractual.</p>
    </Container>
  )
}