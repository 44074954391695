import styled from "styled-components";

export const Container = styled.div`
  /* background-color: white; */
    margin-top: 30px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    width: 420px;
    background-color: #ffffff
`;

export const LineaDivisoria = styled.div`
    background-color: #b99352;
    height: 2px;
    width: auto;
    margin-top: 15px;
`

export const Title = styled.h1`
  color: "black";
`;

export const Parrafo = styled.p`
  color: "black";
  margin-top: 15px;
`;

export const AreasContainer = styled.div`
    /* display: flex; */
    /* align-items: flex-start; */
    /* flex-direction: column; */
    margin-top: 15px;
`

