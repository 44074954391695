import styled from "styled-components";

 const Title = styled.h1`
  color: white;
`;

 const SubTitle = styled.h3`
  color: white;
`;

 const Parrafo = styled.p`
  color: white;
`;

export default Title