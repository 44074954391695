import React from 'react'
import { Container, LineaDivisoria, Title, AreasContainer } from './styles'
import {Acordion} from "../Acordion/index"

export const NuestrasAreas = () => {
    return (
        <Container>
            <Title>
                Nuestras Áreas
            </Title>
            <LineaDivisoria />
            <AreasContainer>
                    <Acordion/>
            </AreasContainer>
        </Container>
    )
}
