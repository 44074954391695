import React, { useEffect, useState } from 'react'
import { Container, Title, Parrafo, LineaDivisoria, QuienesSomosContainer, ImageContainer, ParrafoContainer } from './styles'
import gerbis from '../../assets/images/gerbis.jpg'

export const QuienesSomos = () => {

    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 650);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const gerbisStyle = {
        width: '300px',
        height: '400px',
    };

    return (
        <Container>
            <Title>¿Quiénes Somos?</Title>
            <LineaDivisoria />
            <QuienesSomosContainer isSmallScreen={isSmallScreen}>
                <ParrafoContainer>
                    <Parrafo>Somos una academia especializada en preparar a jóvenes talentos para convertirse en modelos y misses profesionales. Situada en el corazón de Buenos Aires, ofrece una amplia variedad de cursos y programas para ayudarte a desarrollar tus habilidades y destacar en el competitivo mundo del modelaje.

                        Desde sus inicios, Rostro y Tacón se ha ganado una merecida reputación por su estilo de enseñanza innovador y su compromiso con el éxito de sus estudiantes. Su equipo de profesionales, compuesto por expertos en moda, maquillaje, fotografía y pasarela, se encargará de guiarte en cada paso del camino, brindándote todo el conocimiento y la confianza necesarios para triunfar en tu carrera como modelo o miss.
                    </Parrafo>
                </ParrafoContainer>
                <ImageContainer>
                    <img src={gerbis} alt="Gerbis" style={gerbisStyle} />
                </ImageContainer>
            </QuienesSomosContainer>
        </Container>
    )
}
