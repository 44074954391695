import styled from 'styled-components';

export const InicioContainer = styled.div`
text-align: center;
`
export const InicioSubContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const Title = styled.h2`
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    background-color: #78EBF9;
    text-align: center;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
`;

export const Parrafos = styled.p`
font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
`

export const Logo = styled.div`
    width: auto;
    height: 30px;
    background-color: #78EBF9;
`

export const CardsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;

/* @media (max-width: 1500px) {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  } */

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
`

export const LineaDivisoria = styled.div`
    background-color: #b99352;
    height: 2px;
    width: auto;
    margin-top: 15px;
    margin-left: 30px;
    margin-right: 30px;
`

export const TitleGaleria = styled.h1`
  color: black;
  margin-top: 45px;
`;