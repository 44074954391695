import React, { useState } from 'react'
import './gallery.css'
import CloseIcon from '@mui/icons-material/Close';

import agostinaGaleria from '../../assets/images/agostinaGaleria.jpg'
import antonellaGaleria from '../../assets/images/antonellaGaleria.jpg'
import ayelenGaleria from '../../assets/images/ayelenGaleria.jpg'
import azulGaleria from '../../assets/images/azulGaleria.jpg'
import evangelinaGaleria from '../../assets/images/evangelinaGaleria.jpg'
import florenciaGaleria from '../../assets/images/florenciaGaleria.jpg'
import gabrielaGaleria from '../../assets/images/gabrielaGaleria.jpg'
import giovannaGaleria from '../../assets/images/giovannaGaleria.jpg'
import martinaGaleria from '../../assets/images/martinaGaleria.jpg'
import melanieGaleria from '../../assets/images/melanieGaleria.jpg'
import micaelaGaleria from '../../assets/images/micaelaGaleria.jpg'
import misaelaGaleria from '../../assets/images/misaelaGaleria.jpg'
import renataGaleria from '../../assets/images/renataGaleria.jpg'
import solGaleria from '../../assets/images/solGaleria.jpg'
import milagrosGaleria from '../../assets/images/milagrosGaleria.jpg'
import valentinaGaleria from '../../assets/images/valentinaGaleria.jpg'

export const Gallery = () => {

  let data = [
    {
      id: 1,
      imgSrc: agostinaGaleria,
    },
    {
      id: 2,
      imgSrc: antonellaGaleria,
    },
    {
      id: 3,
      imgSrc: ayelenGaleria,
    },
    {
      id: 4,
      imgSrc: azulGaleria,
    },
    {
      id: 5,
      imgSrc: evangelinaGaleria,
    },
    {
      id: 6,
      imgSrc: florenciaGaleria,
    },
    {
      id: 7,
      imgSrc: gabrielaGaleria,
    },
    {
      id: 8,
      imgSrc: giovannaGaleria,
    },
    {
      id: 9,
      imgSrc: martinaGaleria,
    },
    {
      id: 10,
      imgSrc: melanieGaleria,
    },
    {
      id: 11,
      imgSrc: micaelaGaleria,
    },
    {
      id: 12,
      imgSrc: misaelaGaleria,
    },
    {
      id: 13,
      imgSrc: renataGaleria,
    },
    {
      id: 14,
      imgSrc: solGaleria,
    },
    {
      id: 15,
      imgSrc: milagrosGaleria,
    },
    {
      id: 16,
      imgSrc: valentinaGaleria,
    }
  ]


  const [model, setModel] = useState(false)
  const [tempImgSrc, setTempImgSrc] = useState('')


  const getImg = (imgSrc) => {
    setTempImgSrc(imgSrc)
    setModel(true)
  }

  return (
    <div style={{marginTop: '60px'}}>
      <div className={model ? "model open" : "model"}>
        <img src={tempImgSrc} alt=""/>
        <CloseIcon onClick={() => setModel(false)}/>
      </div>
      <div className='gallery'>
        {
          data.map((item, index) => {
            return (
              <div className='pics' key={index} onClick={() => getImg(item.imgSrc)}>
                <img src={item.imgSrc} alt="pic" style={{ width: '100%' }} />
              </div>
            )
          }
          )
        }
      </div>
    </div>
  )
}