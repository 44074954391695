import React from 'react'
import {Title} from './styles'

export const ClasePersonalizada = () => {

  const containerStyles = {
    display: "flex",
    flexDirection: "column",
    padding: '25px',
  }

  return (
    <div style={containerStyles}>
      <h1>Clase Personalizada</h1>
      <p>
      ¡Descubre el poder de la transformación personal con nuestras Clases Personalizadas, diseñadas exclusivamente para ti! Con la flexibilidad de elegir entre una o más disciplinas, esta experiencia de aprendizaje se adapta a tus objetivos individuales y te sumerge en un viaje educativo único.
      </p>

      <p style={{ marginTop: '30px' }}>
      ¡Embárcate en esta experiencia educativa única y descubre el potencial completo de tu belleza y talento! No hay límites para lo que puedes lograr con nuestras Clases Personalizadas. ¿Listo para comenzar tu viaje hacia el éxito personal? ¡Inscríbete ahora y haz que cada aprendizaje cuente!
      </p>
      <p>
        <h2>Para Adquirir la Clase Personalizada siga los siguientes pasos:</h2>
        <p>1) Enviar un mensaje al whatsapp +54 9 1126162507 y coordinar que clases, modalidad y horarios en que desea tomar.</p>
        <p>2) Transferir el dinero de la clase pactado previamente al siguiente CVU:0000007900209548585389 o ALIAS: GERBISYEPEZ.UALA  </p>
        <p>3) Enviar el comprobante de pago al whatsapp para asi confirmar las clases.</p>
        <p>4) Listo!, nos vemos en la Clase Personalizada!</p>
      </p>
      <Title>No subestimes el poder de ese primer paso; hoy plantas, mañana cosechas!</Title>
    </div>
  )
}
