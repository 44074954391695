import styled from "styled-components";

export const MasterClassContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 25px;
`
export const Title = styled.h2`
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  text-align: center;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-top: 50px;
`;

export const Parrafos = styled.p`
font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
`

export const ReelContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  align-items: center;

  @media (max-width: 650px) {
    flex-direction: column;
  }
`

export const NivelesContainer = styled.div`

  display: flex;
  flex-direction: row;
  margin-top: 30px;
  align-items: flex-start;

  @media (max-width: 650px) {
    flex-direction: column;
      }
`
