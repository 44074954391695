import styled from 'styled-components';

export const Container = styled.div`
    background-color: #ffffff;
    margin-top: 30px;
    width: 90%;
    `;

export const LineaDivisoria = styled.div`
    background-color: #b99352;
    height: 2px;
    width: auto;
    margin-top: 15px;
`

export const Title = styled.h1`
  color: black;
  text-align: center;
  background-color: #ffffff;
  font-family: 'Roboto', sans-serif;
`;

export const Parrafo = styled.p`
  color: "black";
  margin-top: 15px;
  font-family: 'Roboto', sans-serif;
`;

export const QuienesSomosContainer = styled.div`

  display: flex;
  margin-top: 30px;
  text-align: center;
  align-items: center;

    ${({ isSmallScreen }) => isSmallScreen && `
    flex-direction: column
  `}
`

export const ImageContainer = styled.div`
  text-align: center;
`

export const ParrafoContainer = styled.div`

display: flex;

${({ isSmallScreen }) => isSmallScreen && `
  width: 250px
`}
`


