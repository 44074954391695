import React from 'react'
import { VideoReel } from '../../index'
import renataVideo from '../../../assets/videos/renataVideo.mp4'
import { Title } from './styles'

export const PreparacionIntegralPage = () => {

  const containerStyles = {
    display: "flex",
    flexDirection: "column",
    padding: '25px',
  }

  const test = {
    alignSelf: 'center',
  }

  return (
    <div style={containerStyles}>
      <h1>Preparación Integral</h1>
      <p>
        El ser Miss es una profesión apasionante que ha cautivado a personas de todo el mundo. Con su mezcla de glamour, estilo y creatividad, las Misses tienen la oportunidad de trabajar en una industria emocionante. Sin embargo, para tener éxito en este campo, es necesario contar con una preparación integral que abarque diferentes aspectos.
        La preparación integral en el mundo Miss implica mucho más que simplemente tener una apariencia atractiva. Si bien es cierto que la imagen es importante, también es fundamental contar con habilidades y conocimientos específicos para destacar en esta industria competitiva.
      </p>
      <div style={test}>
        <VideoReel video={renataVideo} />
      </div>
      <p style={{ marginTop: '30px' }}>
        Además de la apariencia, las Misses también deben desarrollar habilidades de actuación y expresión corporal. Aprender a caminar en una pasarela, posar frente a la cámara y transmitir emociones a través de gestos y movimientos es fundamental. Para ello, es recomendable tomar clases con la ayuda de un mentor.

        Otro aspecto importante en la preparación integral de la belleza es el conocimiento de la industria y el como poner en práctica estrategias que te den una mayor proyección en redes sociales.

        Si lo que estás buscando es ser preparada integralmente contrata nuestra preparación integral y transformare en una Miss de alto impacto
      </p>
      <p>
        <h2>Para Adquirir la Preparación Integral siga los siguientes pasos:</h2>
        <p>1)El costo de preparación integral es de 30USD mensuales o en pesos argentinos al cambio al valor “Dólar Blue”</p>
        <p>Para asegurar tu cupo en nuestra academia deberás realizar el pago a la siguiente cuenta</p>
        <p>CVU:0000007900209548585389  ALIAS: GERBISYEPEZ.UALA</p>
        <p>2) Enviar el comprobante de pago al whatsapp +54 9 1126162507</p>
        <p>3) Enviar junto al comprobante de pago los datos sobre la alumna:</p>
        <ul>
          <li>Nombre completo</li>
          <li>Apellidos</li>
          <li>Edad</li>
          <li>Direccion</li>
          <li>Especificar la modalidad con la que tomara la master class (virtual / presencial)</li>
        </ul>
        <p>4) Esperar mientras verificamos los datos y nos ponemos en contacto contigo.</p>
        <p>5) Estaremos coordinando una reunión para iniciar oficialmente tu preparación.</p>
      </p>
      <Title>No subestimes el poder de ese primer paso; hoy plantas, mañana cosechas!</Title>
    </div>
  )
}
