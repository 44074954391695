import React, { useEffect, useState } from 'react';
import './styles.css';

export const PopupComponent = ({ onClose }) => {
  const [shouldShowPopup, setShouldShowPopup] = useState(false);

  useEffect(() => {
    const lastPopupTime = localStorage.getItem('lastPopupTime');
    const currentTime = new Date().getTime();
    const twentyFourHours = 24 * 60 * 60 * 1000;

    if (!lastPopupTime || currentTime - lastPopupTime > twentyFourHours) {
      setShouldShowPopup(true);
      localStorage.setItem('lastPopupTime', currentTime.toString());
    }
  }, []);

  const openFormInNewTab = () => {
    window.open(
      'https://docs.google.com/forms/d/e/1FAIpQLSd4riFBIiiBPT1mT0WIL9BCwkuTlVHE4FNKszAwessEWm-pJw/viewform?usp=sf_link',
      '_blank'
    );
    onClose();
  };

  return shouldShowPopup ? (
    <div className="popup-overlay">
      <div className="popup">
        <span className="close-btn" onClick={onClose}>
          &times;
        </span>
        <h2 style={{ marginTop: '20px' }}>¡CASTING ACTIVO!</h2>
        <p style={{ marginTop: '30px' }}>
          Regístrate en nuestra base de datos para concursos internacionales.
        </p>
        <button className="apply-button" onClick={openFormInNewTab}>
          Aplicar
        </button>
      </div>
    </div>
  ) : null;
};
