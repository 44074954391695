import React from 'react';
import './styles.css';

export const EmailComponent = () => {
  const emailAddress = 'info@rostroytacon.com';

  return (
    <div>
      <p className="email-address">
        <span className="red-text" onClick={() => window.location.href = `mailto:${emailAddress}`}>
          {emailAddress}
        </span>
      </p>
    </div>
  );
};
