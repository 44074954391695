import React from 'react'
import { CarouselHeader, QuienesSomos, NuestrasAreas, Cards, Gallery} from "../../index"
import 'bootstrap/dist/css/bootstrap.min.css'

import preparacionIntegral from '../../../assets/images/preparacionIntegral.jpg'
import gabrielaMasterClass from '../../../assets/images/gabrielaMasterClass.jpg'
import clasePersonalizada from '../../../assets/images/clasePersonalizada.jpg'
import banner from '../../../assets/images/banner.jpg'

import {BannerComponent} from '../../Banner/index'

import 'bootstrap/dist/css/bootstrap.min.css';
import { InicioContainer, CardsContainer, InicioSubContainer, TitleGaleria ,LineaDivisoria } from './styles'

export const Inicio = () => {

    const masterClassExplication = "PISADA DE EXITO, es nuestro último programa de capacitación en pasarela, nuestra masterclass va dirigída a misses y modelos que tengan como objetivo impactar en cada pasarela, con nosotros aprenderás paso a paso las técnicas y secretos para que tu caminata sea un pase directo al éxito."
    const clasePersonalizadaExplication = 'En nuestra academia ofrecemos clases personalizadas que se adaptan a tu disponibilidad de tiempo y acceso desde cualquier parte del país y el mundo, "ANIMATE A DAR EL PRIMER PASO".'
    const preparacionIntegralExplication = "Si piensas inscribirte en algún concurso asegúrate de tener todas las herramientas para ganar, nuestra academia te ofrece la mejor capacitación de la mano de verdaderos profesionales. Nuestros alumnos son reconocidos por sus conocimientos avanzados y resultados exitosos."


    return (
        <InicioContainer>
            <CarouselHeader />
            <InicioSubContainer>
                <QuienesSomos />
                <BannerComponent imageUrl={banner} linkUrl="https://docs.google.com/forms/d/e/1FAIpQLSd4riFBIiiBPT1mT0WIL9BCwkuTlVHE4FNKszAwessEWm-pJw/viewform?usp=sf_link"/>
                <NuestrasAreas />
                <CardsContainer>
                    <Cards link='/masterClass' title="Master Class" explicacion={masterClassExplication} imagen={gabrielaMasterClass} />
                    <Cards link='/clasePersonalizada' title="Clase Personalizada" explicacion={clasePersonalizadaExplication} imagen={clasePersonalizada} />
                    <Cards link='/preparacionIntegral' title="Preparación Integral" explicacion={preparacionIntegralExplication} imagen={preparacionIntegral} />
                </CardsContainer>
            </InicioSubContainer>
            <TitleGaleria>Galería Destacada</TitleGaleria>
            <LineaDivisoria />
            <Gallery />
        </InicioContainer>
    )
}
