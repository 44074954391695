import styled from "styled-components";

export const Container = styled.div`
    background-color: #3E3E5B;
    margin-top: 30px;
    padding-top: 20px;
    padding-left: 20px;
    min-height: 450px;
    display: flex;
    flex-direction: column;
    /* @media (max-width: 650px) {
    flex-direction: column;
  } */
`;
export const ContainerSecundario = styled.div`
  display: flex;
  @media (max-width: 650px) {
    flex-direction: column;
  }
`

export const Title = styled.h1`
  color: #b99352;
`;

export const Parrafo = styled.p`
  color: #b99352;
  margin-top: 15px;
  margin-left: 8px;
`;

export const RostroYTaconContainer = styled.div`
  margin-left: 80px;
  padding-right: 20px;
@media (max-width: 650px) {
    margin-top: 30px;
    margin-left: 0px;
  }

`