import React, { useState, useEffect } from 'react'
import { Navbar, Container, Nav } from 'react-bootstrap'
import logo from '../../assets/Logo/logoRyT.png'
import './header.css'
import HamburgerMenu from 'react-hamburger-menu'

export const Header = () => {

    const [onScroll, setOnScroll] = useState(false)
    const [menuOpen, setMenuOpen] = useState(false)

    useEffect(() => {
        window.addEventListener('scroll', () => {
            setOnScroll(window.scrollY > 50);
        });
    }, []);

    const disableScroll = () => {
        let x = window.scrollX;
        let y = window.scrollY;
        window.onscroll = function(){window.scrollTo(x,y)};
    }

    const enableScroll = () => {
        window.onscroll = function(){};
    }

    useEffect(() => {
        (menuOpen)? disableScroll() : enableScroll();
    }, [menuOpen])


    const logoStyle = {
        width: '60px',
        height: '60px',
    };

    return (
        <Navbar
            bg='dark'
            variant='dark'
            expand="md"
            style={{ width: '100%', position: 'relative', zIndex: 100 }}
            className={`main-header ${onScroll ? 'on-scroll-header' : ''}`}
            onToggle={() => setMenuOpen(!menuOpen)}
        >
            <Container>
                <Navbar.Brand href='/'>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <img src={logo} alt="Logo" style={logoStyle} />
                        <p style={{ color: 'white', marginTop: '15px' }}>ROSTROYTACON</p>
                    </div>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls='navbar' bsPrefix='navbar-toggler hamburger-menu'>
                    <HamburgerMenu
                        isOpen={menuOpen}
                        menuClicked={() => null}
                        width={25}
                        height={15}
                        strokeWidth={1.5}
                        rotate={0}
                        color={'white'}
                        borderRadius={0}
                        animationDuration={0.6}
                    />
                </Navbar.Toggle>
                <Navbar.Collapse id="navbar">
                    <Nav className="me-auto">
                        <Nav.Link href="/">Inicio</Nav.Link>
                        <Nav.Link href="/preparacionIntegral">Preparación Integral</Nav.Link>
                        <Nav.Link href="/clasePersonalizada">Clases Personalizadas</Nav.Link>
                        <Nav.Link href="/masterClass">Master Class</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}
