import React from 'react';
import './styles.css';

export const PhoneNumberComponent = () => {
  const phoneNumber = '+54 9 1126162507';

  return (
    <div>
      <p className="phone-number" onClick={() => window.location.href = `tel:${phoneNumber}`}>
<span className="red-text">{phoneNumber}</span>
      </p>
    </div>
  );
};
