import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';


export const Cards = function ({title, explicacion, imagen, link}) {

  return (
    <Card style={{ width: '18rem', marginTop: '35px', marginRight:'30px',marginLeft:'30px'}}>
      <Card.Img variant="top" src={imagen}/>
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text>
        {explicacion}
        </Card.Text>
        <Button variant="primary" href={link}>Mas info</Button>
      </Card.Body>
    </Card>
  );
}
