import React from 'react';
import ReactPlayer from 'react-player';


export const VideoReel = ({video}) => {
  return (
    <div className="video-reel-container">
      <ReactPlayer
        url={video}
        width="100%"
        height="500px"
        controls
      />
    </div>
  );
}
