import React from 'react'
import masterClassVideo from '../../../assets/videos/masterClassVideo.mp4'
import { VideoReel } from '../../index'
import { Title, Parrafos, MasterClassContainer, ReelContainer, NivelesContainer } from './styles'

export const MasterClass = () => {

  const test = {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  }

  return (
    <MasterClassContainer>
      <h1>Master Class</h1>
      <p>Bienvenido a nuestra exclusiva Master Class en el fascinante mundo de la belleza! Sumérgete en un programa único diseñado para revelar los secretos detrás de las pasarelas y sus diversas modalidades. Esta experiencia de aprendizaje fusiona a la perfección la elegancia del marketing con la profesionalidad necesaria para destacar en la industria de la moda y la belleza.
        Nuestra Master Class sobre Pasarela y sus Modalidades es un viaje emocionante que te llevará desde los fundamentos hasta los detalles más avanzados de este apasionante universo. Conviértete en el protagonista de tu propia pasarela y descubre cómo expresar tu estilo de manera única y cautivadora.</p>
      <ReelContainer>
        <div style={test}>
          <VideoReel video={masterClassVideo} />
        </div>
        <p style={{ marginTop: '30px', minWidth: '50%' }}>En nuestra Master Class, no solo aprenderás las habilidades técnicas necesarias, sino que también te empoderaremos para destacar en un mundo donde la autenticidad y la innovación son clave. ¡Prepárate para brillar en cada paso que des!
          ¿Estás listo para dar tus primeros pasos hacia el centro de atención en el mundo de la belleza? ¡Inscríbete ahora y comienza tu transformación hacia una carrera de éxito en la pasarela!
        </p>
      </ReelContainer>
      <NivelesContainer>
        <div style={{ flex: 1, marginTop: '30px' }}>
          <h2>NIVEL BÁSICO:</h2>
          <br />
          <ul>
            <li>Técnicas de desplazamiento</li>
            <li>Poses seguras de miss</li>
            <li>Giros básicos y de transición</li>
            <li>Postura correcta de cada parte del cuerpo según la pasarela</li>
            <li>Expresión facial</li>
            <li>Paneo en 4 tiempos</li>
            <li>Desenvolvimiento escénico</li>
            <li>Cómo tener una proyección internacional en pasarela</li>
          </ul>
        </div>
        <div style={{ flex: 1, marginTop: '30px' }}>
          <h2>NIVEL INTERMEDIO:</h2>
          <br />
          <ul>
            <li>Proyección - giros</li>
            <li>Figuras</li>
            <li>Tiempos de ejecución</li>
            <li>Desplazamiento escénico - expresiones</li>
            <li>Actitud</li>
            <li>Cortes laterales</li>
            <li>Poses</li>
            <li>Utilización de elementos - pareos</li>
            <li>Capas</li>
            <li>Batas</li>
            <li>Vestido</li>
            <li>Inducción sobre traje de gala - tiempos</li>
            <li>Ejecución limpia</li>
            <li>Tips</li>
          </ul>
        </div>
      </NivelesContainer>
      <div>
        <h2>Incluye:</h2>
        <br />
        <ul>
          <li>Certificado de participación </li>
          <li>Refrigerio </li>
          <li>Contenido audiovisual de la clase en general</li>
          <li>Material de apoyo en pdf </li>
          <li>Asistencia online durante 7 días posterior a la master class</li>
        </ul>
      </div>
      <div style={{ marginTop: '30px' }}>
        <h2>Para Adquirir la Master Class siga los siguientes pasos:</h2>
        <p>1) El costo de la master class es de 20USD, o en pesos argentinos al cambio del valor “DÓLAR BLUE”</p>
        <p>Para asegurar tu cupo debes abonar el 50% del costo total de la clase (el equivalente a 10USD) tomando en cuenta el valor del día que realices el pago , al siguiente CVU:0000007900209548585389 o ALIAS: GERBISYEPEZ.UALA</p>
        <p>2) Enviar el comprobante de pago al siguiente whatsapp. +54 9 1131138452</p>
        <p>3) Enviar junto al comprobante de pago los datos sobre la alumna:</p>
        <ul>
          <li>Nombre completo</li>
          <li>Apellidos</li>
          <li>Edad</li>
          <li>Direccion</li>
          <li>Especificar la modalidad con la que tomara la master class (virtual / presencial)</li>
          <li>Especificar el nivel (Basico / Intermedio)</li>
        </ul>
        <Parrafos>4) Esperar mientras verificamos los datos y nos ponemos en contacto contigo.</Parrafos>
        <Parrafos> Tener en cuenta que siete días antes de la fecha pautada para la clase te estaremos enviando privado un mensaje con la información detallada de la master class y coordinando el pago de los 10USD restantes al monto completo ya mencionado anteriormente
        </Parrafos>
      </div>
      <Title>No subestimes el poder de ese primer paso; hoy plantas, mañana cosechas!</Title>
    </MasterClassContainer>
  )
}
