import Accordion from 'react-bootstrap/Accordion';

export const Acordion = function BasicExample() {
    return (
        <Accordion >
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                    Pasarela
                </Accordion.Header>
                <Accordion.Body>
                    La pasarela es uno de los momentos más importantes de un concurso de belleza, es por eso que trabajaré tu técnica para lograr una puesta en
                    escena de alto impacto.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header>Foto Pose</Accordion.Header>
                <Accordion.Body>
                    Las sesiones fotográficas son de suma importancia cuando estas en proceso de participar de algún certamen, es por eso que conmigo aprenderás esos trucos para que tus fotos
                    te hagan destacar.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header>Expresion Corporal</Accordion.Header>
                <Accordion.Body>
                    La expresión corporal es fundamental en la forma en que ejecutamos una pasarela de impacto, contrata nuestra clase y comenza a construir tu sello personal en pasarela.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
                <Accordion.Header>Labor Social</Accordion.Header>
                <Accordion.Body>
                    Los concursos de belleza son una plataforma de alto alcance social y mediático, momento perfecto para hacer conocer tus ideas y trabajo de labor social.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
                <Accordion.Header>Estrategias</Accordion.Header>
                <Accordion.Body>
                    Al entrar en competencia es de total importancia contar con estrategias que te posicionen como una fuerte competidora en redes sociales y llamar la atención del público.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
                <Accordion.Header>Historia Miss</Accordion.Header>
                <Accordion.Body>
                    Cada concurso es una marca unica que busca un perfil específico y posee una travectoria con momentos iconicos que cada reina debe conocer para hacer historia en uno de ellos.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
                <Accordion.Header>Estilismo</Accordion.Header>
                <Accordion.Body>
                    Una imagen acorde a tu esencia unica duplicará las posibilidades de lograr impacto visual antes, durante y después de tu participacion en cualquier escenario.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
                <Accordion.Header>Oratoria</Accordion.Header>
                <Accordion.Body>
                    Un concurso de belleza también busca en sus reinas historias que inspiren, acciones que transformen y sobre todo mensajes que impacten.
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}